<template>
  <div class="q-ma-md">
    <pro-deck
      :title="getRes('Form.Section.ResubmitPricingDocumentsOffer')"
      :cards="items"
      v-slot="{ card }"
    >
      <pro-deck-card v-bind="card" expandable>
        <template>
          <div class="row justify-between items-center">
            <div class="col-auto">
              <span>{{ `Closing Date: ${formatDate(card.closingDate)}` }}</span>
            </div>
            <div class="col-auto">
              <span>{{ `Due in: ${getDueDate(card.closingDate)}` }}</span>
            </div>
            <div class="col-auto">
              <q-btn
                no-caps
                class="col-2 q-ml-md"
                padding="xs md"
                outline
                color="primary"
                :label="getRes('System.Button.View')"
                :to="getViewRequestForBestFinalOffer(card.ptnId)"
                style="width: 65px"
              />
            </div>
          </div>
        </template>
      </pro-deck-card>
    </pro-deck>
  </div>
</template>

<script>
/*eslint-disable*/
//import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";

export default {
  components: { ProDeckCard, ProDeck },
  data() {
    return {
      items: [],
      workflowCode: "",
    };
  },

  methods: {
    getDueDate(date) {
      const currentDate = new Date();
      let delta = (date.getTime() - currentDate.getTime()) / 1000;
      const days = Math.floor(delta / 86400);
      delta -= days * 86400;

      const hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;
      let str = "";
      if (days !== 0) {
        str += `${days} Days and `;
      }
      str += `${hours} Hours`;
      return str;
    },

    formatDate(date) {
      return this.$proSmart.common.getFormattedDate(new Date(date + "+0800"));
    },

    getViewRequestForBestFinalOffer(ptnId) {
      return {
        name: "DocumentViewRequestForBestAndFinalOfferDocRef",
        params: {
          ptnId,
        },
      };
    },
  },

  async created() {
    const { id } = this.$route.params;
    const res = await this.$proSmart.documentUi.getTendererPtn(
      this,
      parseInt(id)
    );
    this.items = res.reduce(
      (list, { closingDate, documentRef, ptnId }) => [
        ...list,
        {
          closingDate: new Date(closingDate + "+0800"),
          title: `${documentRef}`,
          ptnId,
        },
      ],
      []
    );
  },
};
</script>


